@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Quicksand:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  scrollbar-color: #EDEDED transparent;
  scrollbar-width: thin;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

/* total width */
::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 14px 14px transparent;
  border: solid 4px transparent;
  background-color: #FFFFFF;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 14px 14px #EDEDED;
  border: solid 4px transparent;
  border-radius: 14px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

/* Disable arrow in input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

#root, .app, .main-container, .page-layout {
  height: 100%;
}

:root {
  /*--main-bg-color: brown;*/
}

.main-container {
  display: flex;
  position: relative;
}
header .logo {
  width: 127px;
  height: 27px;
  color: #534D49;
}
header .right button {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 28px;
  color: #232323;
}
header .right button:not(:last-child):after {
  content: '';
  position: absolute;
  width: 1px;
  height: 26px;
  right: 0;
  background: #E4E4E4;
  mix-blend-mode: multiply;
  border-radius: 8px;
}
button.checked-option-btn {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.6) !important;
  border: 1px solid rgba(255, 255, 255, 0.6) !important;
  mix-blend-mode: normal;
  border-radius: 12px;
  text-transform: none;
  cursor: default;
  color: #7A5844 !important;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.overlay-hl {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  z-index: -1;
}
.overlay-hr {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  z-index: -1;
}
.position-relative {
  position: relative;
}
.overlay-1 {
  background: #D5F0F0;
}
.overlay-2 {
  background: url(/static/media/overlay-2.026d2e97.svg);
  background-position-y: -4px;
  background-size: cover;
}
.overlay-3-left {
  background: #FFFFFF;
  transform: matrix(1, 0, 0, -1, 0, 0);
}
.overlay-3-right {
  background: url(/static/media/overlay-3-right.54f1f710.svg) no-repeat;
  background-size:cover;
}
.overlay-4 {
  background-color: #F8F8F8;
}
.overlay-5-left {
  background: #E9F4F4;
}
.overlay-5-right {
  background: url(/static/media/overlay-5-right.60748832.svg) no-repeat;
  background-size:cover;
  mix-blend-mode: soft-light;
}

.overlay-screenshot {
  background: url(/static/media/Display@2x.546f8132.png) top no-repeat;
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 617px;
  bottom: 0;
}
div.footnote {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 32px;
  color: #484848;
  mix-blend-mode: normal;
}
.magnifier-wrapper {
  margin-top: -199px;
  position: relative;
}
.magnifier {
  max-width: 100%;
}
.parallax-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.magnifier-parallax {
  position: absolute;
  height: 100%;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  color: #B16823;
  opacity: .5;
  overflow: hidden;
}

.search-group {
  height: 68px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  background-color: #FFFFFF;
}
.search-group input,
.search-group input:focus {
  width: 100%;
  border: none;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  color: #656565;
}

.card {
  background: #FFFFFF;
  mix-blend-mode: multiply;
  box-shadow: 0 0 44px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  padding: 0;
}

.card .separator {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVcAAAACCAYAAADsBZ/oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFcSURBVHgB3dXbUcJAFAbgTYBnN8kkr8YOYgfGCrACKQE60AqgA7UCSoAO0AYkPufqc27+UZhZl2T2cHlg+GcYyMk3meWcZdGSJFkzxlz2l8A0zRsmBPdXePPY/+y4OI7nmqYND3Wo3RqGkQnuFbVHlUvTdFrX9fiCnQ8XbAvoyxi1qeyKonhwHOdd4VhZlr5t28t9XRRFI13XX87FYc0j9OVNcEO4ucqFYXjX6/Uaxw9xmNHEsqzZ9hpz81BbEJyL2uqC3TPck+D4xrmSm8FNVA5Z4uzwCW4Bdy88X4P9PAO31pk6GcE0m5Mf48SDZuOuGSH4Uh7FVVWlEd13xy0uua8Oc2pHCR8MBpQ5Zf1+P6A49FWeR9taSHtjD0feR3Lk9SpC7qtcwAGeitf4U+OM8Dw4l+LyPG/MMX3lLabNuSd21J6SfoeY5wfRBcLn30O4w1F6Wne4uuV1pXI//pF4aPHGoHAAAAAASUVORK5CYII=) center repeat-x;
}

.card .card-header {
  padding: 20px 60px;
}
.card .card-section {
  padding: 0 20%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #484848;
  mix-blend-mode: normal;
  height: 150px;
}

.cards-item {
  width: 25%
}

.text-below-cards {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #656565;
  mix-blend-mode: normal;
  width: 40%;
}

.footer-links .column-header {
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 42px;
  color: #7A5B44;
}

.footer-links .column-link {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #232323;
}
.footer-links .column-link:hover {
  text-decoration: none;
  color: #7A5B44;
}

.footer {
  padding: 35px 50px;
  background: #F8F8F8;
}
.footer .footer-copyright {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-align: right;
  color: #232323;
}

.icon {
  width: 30px;
  height: 30px;
}
.icon-fb {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAETSURBVHgB7VXtDYIwEL0SB9ANKiygG+AEjqAb4AiO4AaOYJzAFXQA8EZwAVIfBgghVziMRn74kqaf9+7jtUD0ZZghh6210yAIEmNMjGnc2uY0TedtmwnpyS3ILyC3viPSYkBKNMgZ/SrP8xkiNkXrslNlEEXRtoyQQbxk5gcpoXKAiBPnHIF8V5DD4QbLB7Rpn62qRCBfFD3Iz+V8L5CzZKsWuYlK6L76F1CL/C68GYRheO+4ki9AC9eYiu/Am4FAztUAGjAp0auBVOcsy+pIyyt8RLtJ9p/QYF32J2mzN4NGnesaS/rgjQzLQKizrQaSPsCVBHgzaNXZSWdG8Q7+Dn7vYNDX1HebuqD9H7CwzDQGPAEuBWZRi1xAhgAAAABJRU5ErkJggg==) center no-repeat;
}
.icon-fb:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFHSURBVHgB7VXNTsJAEJ6pPXnCN6hvoG9QoyTevMvBvgHcTPDiRTx44Q1MTPDiQT2DkUeAJ4BH4AIkQBl2IbC0TNsp4e/Al3Q32+58s/PN7BRgy8A0mwuemzkd2HlCcJWhG6JqlyrV87CNDUI8erfOydD/BySHPxU53FsLhJiSKxIiavsIV73u6KxUqaF+4uxEERTvs96cvN/zL8s/9Q4IIXKASHlSs2VjQZM/5a4fCLEMBJkkW5FEivxCzy8ftd/Z2noOk+voOFtxkkMuHT0m6a8hTvK6iIygmMu2okrP7Lkhs+LvQUwEQfJljTm9icbAITEHnM6vn3+Lk05LGOmdAJuc/QZyML7Toyrlb+5rYgRGZ6Mxl5/RwEoXwarOy4Sr+Xn7qjY4nsgIAjoHqsXgIO7B0cH+HaTqplHVFAfZ/4DtPXz/3zkmjgmIFhm90HMAAAAASUVORK5CYII=) center no-repeat;
}
.icon-instagram {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHeSURBVHgBtVXbccIwEJQNBZAOHGgAKojpIB0AFRgqwFQQd4DTQVJBoILwz8sdxAUAzq7n5BEMIxAedsac5ZN2dafjpNST4ZmDIAhavu9HnueFGIbKAUVRrLAu2W63n1cFQB6A/AeTAlUP2fF47GfAmUC73d4LeQY7OhwOK8zJ1Z3odDrviOJDc0Ckx/W+OIemY7PZLFzICaTm63Q69SCSYchsjPm9FAB5RAvysSsxU4voY9pyx74/Es63SgCqXVpM+FaOACGjn9JyzNSKK+RP8x4S5hcm0ovMikFaUpArWtlkjvnV2psCCH8KE5vfQM6IU/i6u91ucuk/i1DZd87wYyGd4IxesGtWHvOc49sYcwY2DqsAMNDkqKxEFwBEUhEhhnUEysPHwaWXDpalvIaqhoBGSz2IWwJlyekSNIEDLv87rCgbgbWKkPsZCELWOQgLlCIbWS4NMZY5iY3DGgFbBkWEKG40Gns8fwb57LJ7OgmISIwd9/C64O7loXCfvlvrz1LE++BaL1qv18xzXz0AHcGiVGs2u6ompK1Uh6+b3VLsnFGoB8G1vBP4rg/f0w7k+VffCXgmxh/pLmJGjyqbaw6sf60EZNJzr0wNNjiEGUnHdAEra4m2krheWrXwDyHL8yavLgLwAAAAAElFTkSuQmCC) center no-repeat;
}
.icon-instagram:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJsSURBVHgBtVVNctowFH6SDUyySm9AbwAnqGlIpsssW2Am5gTAikIXMYvEZRXfoKYDtEu662SguCcoNwg3KIuUX1uqnsAMMA2JzfTbyNKTvk967/k9gP8Msjkp6trJ8VwtcAKaMGgQDAMC1Lpu3TX+KVDW38SVhdcH4HE4CGToRZRU3f4+3BKoZs/ukZxzPmSU5GcP7sDqOKPn0lZypxfA4JYQEkeRcWSRtGxnRCV55kz3ySdjL1lvdp0g5Aiz2evEol4SOZDraK4WcV2VzyC8wMVIVVIMSoyupXNXZ1HVNoRbyrl0XuHQF755hXb5AkGewPH6c/cbBASSEwJXOOL8SHUH8tKrJFGfQ1J5d34BlBU2MmudMYy5NqUq4IgGQ/i9mk2vzz4pUM2lr4AzY2c5wYHZlWw6Yba6JeR97DyFfeQYfL48LNxYGv9xX9y0uoRxlhdLI/Gi4ofs+eU+jr0CnPBLn1zc1PIT4GP7hy1sKCIyk+mhBcgq+JPI0r+bwLRc7dEgrICP2BROICT2u0hki9xEVX3XVs2dFjb3PIa9WcQI1MRPo2GeVzNp7kXVxgymIyyIfvCFhAVhX4AlQ1TWmpwQMJSFe3+8UH/jt6TmUDNbvUZoAYTZ7BrA3aRwhSOmmEUj/PYIpMy2sD2BLRdhP8AKuLvppu2gn1MQAn4tcnCMTdUEHAhZtmEjQeQqgZ84KAr5hK+AkMCzhNPb5WwZfNlwDGGYzZVffrPgjJTML3edIMT4eryg31fMdu/lWgBRfqvFKVX6S5HwkB2Rean6V2e4JeDjfea1TgnFnyhQPGQchasnD64VtGkdhL+vYBNa2uIlmAAAAABJRU5ErkJggg==) center no-repeat;
}
.icon-twitter {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI1SURBVHgB7VU7UsMwEJXzqeNJLmDyqTEdHeYEmJIKUlLBDXBOADkB5gT4BkBJBfTJxJwgPkA+vOesjJyJ84EZKt6MLGml3be70spK/WMDLPUDOEC5XL7B0Eez0RK0aDqd9mKg3W57s9ns3rKsb4JmszmiAJsOsCcpMt5qtc7R3YnhZVDvHc2TeVzil4ww7tA5eHar1niOLhTjfTizNxwOLfaYP4jcM1Sey/zatn0NgkMRuvV63a3Vaq8JYBI0Gg167pIEhi/1OvvxeBxB71NI6EgC4rM0AhjfZz+fz3toMYY+InliOsRrjRN+mOtVEUIe6QhgMz0PS1L0BsMu809mSZNvhor1DyhdccK0qALAqbm5J40AJ64PLCErFk8x7tKwyD1tfFdUihZAEqIL5Uq6jJByEI2KdIx0xjkCKPFq0ZCnFrckAyMShUht8rZSceBIjiBNEYQvMj9RvwBS7Yu9jxwBFkKZ+0u3ZlekDpZKpShHIJXbl8VHcNhqRxjFGg8Gg+ccAYErGrAGsMkFydOukfDtkWFgyjMCRoFNx5pEF5raAnjHbrT3uH0PKwmEJCaJWtx/KoQgGW0wfgXjAcfou8vrWR0w79Vq1UGqjtSWEM8DMd4zc58RyDPtcALvuVGvJRj3J5PJ3bISDxSp5P/AM4wHagWKKjkS4zHGNqOTat6HnMY92cfz6q7yXCN1F3m+gBJz6artkEW37ueUEWh0Oh2+qEfipaOMvxZkMSuURbTO4z/HF3w6HL6Rqs9jAAAAAElFTkSuQmCC) center no-repeat;
}
.icon-twitter:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALMSURBVHgB7VU/TxRREJ/Zt3sQqvsGLhY2SlhiY4KRVU5jB3bIkQClFXSYo+CuAa/i7hO4JHKhc0sLkDORwkqMlias3+AK0cS9feObt7frHd4Cp4mVv2Tz/s/vN/PmzQL8xwVA+AOsLT20RRhuAOIsEOSVlRYQ+ZFlVareq2BtruAKgc97CErF+yfcfrPCiZrXbGUZXy9OLxJiTRv+HS0COFZGXR4QUWBoRQsFNUE2fyOhtQ3nKCdAj41LonoUtUc3d/eRWwLaUVvyiXGtHqEpuDN1Y3RVjW515p3bY1edyZvX3h0df+7x5M71KzUEdJQQb6tx8OToU6DXuX378cSfHLO/KDtMYrM3URQ91h4ol8djl6DCbinmWRFGh+vFB4usOlUEOMOtOljp5+FwTvppeAAq1b1mgHFcC+/VhBP+wAlDhC1hiG19gR2otaYi/mAgrvCYwwIZKBUL1L0n9gBQXxgbZ9bNxsEjSXKZDcfKwU2MDwoza+FZ47WnGm9tzrUNUzggOfbMRidZZ3hv3MOgl4DksQqJLQzLZaPdh9gj1fDnw4VyTZvjqTIqSKY6l0xvdAs0A38BIUnfG99XD8GQJT1uOXu6s2ZgoKEFkkS/h6DMLxeprlWE7ZerS24eBkTyWDnNq3v7zR4CRs6Myryous5IaB4O6omguPaolCt3z6cE7IWU0d2EJHlocAmUFgobifqt3YOdvgQMzhhNovNfHQDpJUUw2/j0isqOMvelgctn19N3wHG3Ti1bmDCllKQbiCScq7xjnMtMdfdX7FOCWKGqpKEa5XQSJzW8RQj176dR7ewhrveGADbuJsa3GvvlfiJMVoh4prSonwdKrEfQDoaGIa9eaN7AnIMoxxWp213vOSz9lCfQlp/O31sy0OBa48DlEHv3tV2r+dk/p5QgQWnedVTFmwJWSGgnRbDjVsAvlAz0qy+yFf9z/ASfqU9X8hqIAQAAAABJRU5ErkJggg==) center no-repeat;
}

/* SIDES LAYOUT FOR AUTH PAGES ETC */
.sides-layout {

}
.sides-layout .layout-side {
  position: relative;
}
.sides-layout .layout-side-left {
  width: 30%;
  background: #D5F0F0;
}
.sides-layout .layout-side-left .top-half-overlay {
  position: absolute;
  top: 0;
  height: 50%;
  width: 100%;
}
.sides-layout .layout-side-left .bottom-half-overlay {
  position: absolute;
  top: 50%;
  height: 50%;
  width: 100%;
  background: url(/static/media/overlay-auth-left-bottom.580feec4.png) top no-repeat;
}
.sides-layout .layout-side-right {
  width: 100%;
}

/* Fixed header for mobile sides layout */
.sides-layout .sides-header {
  top: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
  justify-content: center;
  background: #FFF;
  z-index: 1100;
}

.sides-layout .sides-header .sides-header-absolute-inner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%
}

#landing-wrapper {
  overflow-y: auto;
}

#landing-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 14px 14px transparent;
  border: solid 4px transparent;
  margin-top: 64px;
}

.logo-button:hover {
  background: none !important;
}

@media (max-width: 600px) {
  .sides-layout .sides-header.scrolled {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12);
  }
  .magnifier-wrapper {
    margin-top: -180px;
    position: relative;
  }
  .overlay-screenshot {
    background: url(/static/media/Display@2x.546f8132.png) top no-repeat;
    background-size: contain;
    position: absolute;
    width: calc(100% - 10px);
    height: 100vw;
    bottom: 0;
    margin: 0px 5px;
  }
  .footer {
    padding: 5px;
  }
  .text-below-cards {
    width: 90%;
  }
}

@media (min-width: 600px) {
  .sides-layout .sides-header {
    position: relative;
    justify-content: flex-end;
  }
  .sides-layout .sides-header .sides-header-inner {
    position: relative;
    justify-content: flex-end;
    margin-right: 50px;
  }
  .sides-layout .layout-side-right {
    width: 70%;
  }
}
div.circle {
  position: relative;
  height: 230px;
  width: 230px;
  border-radius: 50%;
  margin: 20px;
  border: none;
}

div.circle:before,
div.circle:after, span.circle1 {
  position: absolute;
  content: '';
  z-index: 999;
  height: 100%;
  width: 100%;
  top: -7px;
  left: -7px;
  border-radius: 50%;
  border: 6px solid rgb(255, 255, 255);
  animation: glow-grow 8s ease-out infinite;
  -webkit-animation: glow-grow 8s ease-out infinite;
  -moz-animation: glow-grow 8s ease-out infinite;
}

div.circle:after {
  animation-delay: 1.33s;
}

span.circle1{
  animation-delay: 2.66s;
}

@keyframes glow-grow {
  0% {
    opacity: .3;
    transform: scale(0.1);
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -o-transform: scale(0.1);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
  }
}

.profilepicture {
  animation: pulseImage 6s linear infinite;
  -webkit-animation: pulseImage 6s linear infinite;
  -moz-animation: pulseImage 6s linear infinite;
  position: absolute;
  width: 230px;
  height: 230px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 999px;
  box-sizing: border-box;
  z-index: 2;
  background: radial-gradient(50% 50% at 50% 50%, #C4C4C4 0%, rgba(196, 196, 196, 0) 100%);
  opacity: 0.7;
}

@keyframes pulseImage {
  0% {
    transform: scale(.9);
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -ms-transform: scale(.9);
    -o-transform: scale(.9);
  }
  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
  }
  100% {
    transform: scale(.9);
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -ms-transform: scale(.9);
    -o-transform: scale(.9);
  }
}

