div.circle {
  position: relative;
  height: 230px;
  width: 230px;
  border-radius: 50%;
  margin: 20px;
  border: none;
}

div.circle:before,
div.circle:after, span.circle1 {
  position: absolute;
  content: '';
  z-index: 999;
  height: 100%;
  width: 100%;
  top: -7px;
  left: -7px;
  border-radius: 50%;
  border: 6px solid rgb(255, 255, 255);
  animation: glow-grow 8s ease-out infinite;
  -webkit-animation: glow-grow 8s ease-out infinite;
  -moz-animation: glow-grow 8s ease-out infinite;
}

div.circle:after {
  animation-delay: 1.33s;
}

span.circle1{
  animation-delay: 2.66s;
}

@keyframes glow-grow {
  0% {
    opacity: .3;
    transform: scale(0.1);
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -o-transform: scale(0.1);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
  }
}

.profilepicture {
  animation: pulseImage 6s linear infinite;
  -webkit-animation: pulseImage 6s linear infinite;
  -moz-animation: pulseImage 6s linear infinite;
  position: absolute;
  width: 230px;
  height: 230px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  border-radius: 999px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 2;
  background: radial-gradient(50% 50% at 50% 50%, #C4C4C4 0%, rgba(196, 196, 196, 0) 100%);
  opacity: 0.7;
}

@-webkit-keyframes pulseImage {
  0% {
    -webkit-transform: scale(.9);
  }
  50% {
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(.9);
  }
}

@keyframes pulseImage {
  0% {
    transform: scale(.9);
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -ms-transform: scale(.9);
    -o-transform: scale(.9);
  }
  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
  }
  100% {
    transform: scale(.9);
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -ms-transform: scale(.9);
    -o-transform: scale(.9);
  }
}

@-moz-keyframes pulseImage {
  0% {
    -moz-transform: scale(.9);
  }
  50% {
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(.9);
  }
}
