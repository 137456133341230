@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Quicksand:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900');

:root {
  /*--main-bg-color: brown;*/
}

.main-container {
  display: flex;
  position: relative;
}
header .logo {
  width: 127px;
  height: 27px;
  color: #534D49;
}
header .right button {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 28px;
  color: #232323;
}
header .right button:not(:last-child):after {
  content: '';
  position: absolute;
  width: 1px;
  height: 26px;
  right: 0;
  background: #E4E4E4;
  mix-blend-mode: multiply;
  border-radius: 8px;
}
button.checked-option-btn {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.6) !important;
  border: 1px solid rgba(255, 255, 255, 0.6) !important;
  mix-blend-mode: normal;
  border-radius: 12px;
  text-transform: none;
  cursor: default;
  color: #7A5844 !important;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.overlay-hl {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  z-index: -1;
}
.overlay-hr {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  z-index: -1;
}
.position-relative {
  position: relative;
}
.overlay-1 {
  background: #D5F0F0;
}
.overlay-2 {
  background: url("../landing/backgrounds/overlay-2.svg");
  background-position-y: -4px;
  background-size: cover;
}
.overlay-3-left {
  background: #FFFFFF;
  transform: matrix(1, 0, 0, -1, 0, 0);
}
.overlay-3-right {
  background: url("../landing/backgrounds/overlay-3-right.svg") no-repeat;
  background-size:cover;
}
.overlay-4 {
  background-color: #F8F8F8;
}
.overlay-5-left {
  background: #E9F4F4;
}
.overlay-5-right {
  background: url("../landing/backgrounds/overlay-5-right.svg") no-repeat;
  background-size:cover;
  mix-blend-mode: soft-light;
}

.overlay-screenshot {
  background: url("../landing/backgrounds/Display@2x.png") top no-repeat;
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 617px;
  bottom: 0;
}
div.footnote {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 32px;
  color: #484848;
  mix-blend-mode: normal;
}
.magnifier-wrapper {
  margin-top: -199px;
  position: relative;
}
.magnifier {
  max-width: 100%;
}
.parallax-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.magnifier-parallax {
  position: absolute;
  height: 100%;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  color: #B16823;
  opacity: .5;
  overflow: hidden;
}

.search-group {
  height: 68px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  background-color: #FFFFFF;
}
.search-group input,
.search-group input:focus {
  width: 100%;
  border: none;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  color: #656565;
}

.card {
  background: #FFFFFF;
  mix-blend-mode: multiply;
  box-shadow: 0 0 44px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  padding: 0;
}

.card .separator {
  background: url("../img/cards/separator.png") center repeat-x;
}

.card .card-header {
  padding: 20px 60px;
}
.card .card-section {
  padding: 0 20%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #484848;
  mix-blend-mode: normal;
  height: 150px;
}

.cards-item {
  width: 25%
}

.text-below-cards {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #656565;
  mix-blend-mode: normal;
  width: 40%;
}

.footer-links .column-header {
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 42px;
  color: #7A5B44;
}

.footer-links .column-link {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #232323;
}
.footer-links .column-link:hover {
  text-decoration: none;
  color: #7A5B44;
}

.footer {
  padding: 35px 50px;
  background: #F8F8F8;
}
.footer .footer-copyright {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-align: right;
  color: #232323;
}

.icon {
  width: 30px;
  height: 30px;
}
.icon-fb {
  background: url("../img/icons/social/fb.png") center no-repeat;
}
.icon-fb:hover {
  background: url("../img/icons/social/fb-hover.png") center no-repeat;
}
.icon-instagram {
  background: url("../img/icons/social/instagram.png") center no-repeat;
}
.icon-instagram:hover {
  background: url("../img/icons/social/instagram-hover.png") center no-repeat;
}
.icon-twitter {
  background: url("../img/icons/social/twitter.png") center no-repeat;
}
.icon-twitter:hover {
  background: url("../img/icons/social/twitter-hover.png") center no-repeat;
}

/* SIDES LAYOUT FOR AUTH PAGES ETC */
.sides-layout {

}
.sides-layout .layout-side {
  position: relative;
}
.sides-layout .layout-side-left {
  width: 30%;
  background: #D5F0F0;
}
.sides-layout .layout-side-left .top-half-overlay {
  position: absolute;
  top: 0;
  height: 50%;
  width: 100%;
}
.sides-layout .layout-side-left .bottom-half-overlay {
  position: absolute;
  top: 50%;
  height: 50%;
  width: 100%;
  background: url("../landing/backgrounds/overlay-auth-left-bottom.png") top no-repeat;
}
.sides-layout .layout-side-right {
  width: 100%;
}

/* Fixed header for mobile sides layout */
.sides-layout .sides-header {
  top: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
  justify-content: center;
  background: #FFF;
  z-index: 1100;
}

.sides-layout .sides-header .sides-header-absolute-inner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%
}

#landing-wrapper {
  overflow-y: auto;
}

#landing-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 14px 14px transparent;
  border: solid 4px transparent;
  margin-top: 64px;
}

.logo-button:hover {
  background: none !important;
}

@media (max-width: 600px) {
  .sides-layout .sides-header.scrolled {
    box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12);
  }
  .magnifier-wrapper {
    margin-top: -180px;
    position: relative;
  }
  .overlay-screenshot {
    background: url("../landing/backgrounds/Display@2x.png") top no-repeat;
    background-size: contain;
    position: absolute;
    width: calc(100% - 10px);
    height: 100vw;
    bottom: 0;
    margin: 0px 5px;
  }
  .footer {
    padding: 5px;
  }
  .text-below-cards {
    width: 90%;
  }
}

@media (min-width: 600px) {
  .sides-layout .sides-header {
    position: relative;
    justify-content: flex-end;
  }
  .sides-layout .sides-header .sides-header-inner {
    position: relative;
    justify-content: flex-end;
    margin-right: 50px;
  }
  .sides-layout .layout-side-right {
    width: 70%;
  }
}